// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from 'jquery';
import 'popper.js';
import 'bootstrap';

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("chart.js")


//import 'bootstrap/dist/js/bootstrap'

const renderCharts = () => {
  [...document.querySelectorAll('.chart')].forEach((element) => {
    var ctx = element.getContext('2d');
    var myChart = new Chart(element, {
      type: element.dataset.type,
      data: JSON.parse(element.dataset.data),
      options: JSON.parse(element.dataset.options)
    });
  });
}

document.addEventListener('turbolinks:load', function () {
  Promise.resolve().then(renderCharts);
  $("#search_form").on("ajax:success", (event) => {
    $('#searchModal').modal('show');
  }).on("ajax:error", (event) => {
    $('#searchModal').modal('hide');
    alert('Het zoeken is mislukt');
  });
});


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
